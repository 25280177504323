import React from "react";
import ThankYou from "../../../page-components/ThankYou";

export default function ThankYouPage() {
  return (
    <div className="mt-24">
      <ThankYou
        thankYouMessage="Please keep an eye on your inbox. We’ll be sending your details for attending our free webinar, The Path to Profit for New Restaurants."
        thankYouSubheader={`In the meantime, find more resources for running a successful restaurant on the SpotOn blog, <a href="https://spoton.com/blog" target="_blank" class="text-primary">Heart & Hustle.</a>`}
        phoneText={null}
        phoneNumber={null}
      />
    </div>
  );
}
